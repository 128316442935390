 
import React, { useRef } from 'react'
import { useGLTF, 

 } from '@react-three/drei'


export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/model.gltf')
  return (
    <group ref={group} {...props} dispose={null}>
<mesh geometry={nodes.Cylinder004.geometry} material={nodes.Cylinder004.material} />
<mesh geometry={nodes.Cylinder007.geometry} material={nodes.Cylinder007.material} />
<mesh geometry={nodes.Cylinder011.geometry} material={nodes.Cylinder011.material} />
<mesh geometry={nodes.ground.geometry} material={materials['Material.008']} />
<mesh geometry={nodes.Cylinder015.geometry} material={nodes.Cylinder015.material} />
<mesh geometry={nodes.Cylinder005.geometry} material={nodes.Cylinder005.material} />
<mesh geometry={nodes.cactus.geometry} material={materials['Material.007']} />
<mesh geometry={nodes.Cylinder009.geometry} material={nodes.Cylinder009.material} />
<mesh geometry={nodes.Cylinder013.geometry} material={nodes.Cylinder013.material} />
<mesh geometry={nodes.Cylinder017.geometry} material={nodes.Cylinder017.material} />
<mesh geometry={nodes.pot.geometry} material={materials['Material.006']} />

    </group>
  )
}
 
useGLTF.preload('/model.gltf')